import { render, staticRenderFns } from "./PrivacyPolicyContent.vue?vue&type=template&id=1b9a4d7a&"
import script from "./PrivacyPolicyContent.ts?vue&type=script&lang=ts&"
export * from "./PrivacyPolicyContent.ts?vue&type=script&lang=ts&"
import style0 from "./PrivacyPolicyContent.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports